@value vPrimary1, vGray14, vGray15 from "../../../../styles/variables.css";

.item {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid vGray15;
}

.item + .item {
    margin-top: 10px;
}

.column + .column {
    margin-left: auto;
    padding-left: 20px;
    text-align: right;
}

.name {
    font-size: .875rem;
}

.date {
    font-size: .875rem;
    color: vGray14;
}

.value {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
}

.unit {
    font-size: .875rem;
}

.more {
    margin-top: 14px;
    color: vPrimary1;
}
