.arrow {
    display: block;
    border-style: solid;
    border-color: #000;
    border-width: 0 1px 1px 0;
}

.arrow_small {
    width: 8px;
    height: 8px;
}

.arrow_medium {
    width: 10px;
    height: 10px;
}

.arrow_large {
    width: 12px;
    height: 12px;
}

.arrow_down {
    transform: rotate(45deg) translate(-1px, -1px);
}
