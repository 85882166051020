@value vLoadingOpacity, vPrimary1, vGray14 from "../../../styles/variables.css";
@value tablet-landscape-up from "../../../styles/breakpoints.css";

.item {
    position: relative;
    padding: 12px;
    cursor: pointer;
}

.item_active {
    cursor: default;
}

.item_active:after {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -2px;
    height: 3px;
    background: vPrimary1;
    border-radius: 4px;
    content: '';
}

.item_counted {
    padding-right: 0;
}

.item_badged {
    padding-right: 30px;
}

.text {
    font-size: 1.25rem;
    line-height: 1;
    color: #000;
}

.text_active {
    color: vPrimary1;
}

.count {
    padding-left: 10px;
    color: vGray14;
}

.badge {
    position: absolute;
    top: 4px;
    margin-left: 1px;
}

.disabled {
    cursor: default;
    pointer-events: none;
    user-select: none;
    opacity: vLoadingOpacity;
}

@media tablet-landscape-up {
    .item {
        padding: 12px 30px;
    }

    .item_counted {
        padding-right: 0;
    }
}
