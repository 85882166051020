@value tablet-landscape-up, desktop-up from "../../styles/breakpoints.css";

.content {

}

.breadcrumbs {
    padding-top: 24px;
    padding-bottom: 0;
    min-height: 44px;
    overflow-x: auto;
    overflow-y: hidden;
}

.breadcrumbs::-webkit-scrollbar {
    display: none;
}

.errorText {
    padding: 40px 0;
    text-align: center;
}

@media tablet-landscape-up {
    .breadcrumbs {
        padding: 24px 0 0 24px;
        overflow-x: hidden;
    }

    .breadcrumbs::-webkit-scrollbar {
        display: initial;
    }
}

@media desktop-up {
    .content {

    }
}
