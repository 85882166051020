@value vLoadingOpacity, vGray4, vGray25 from "../../styles/variables.css";

.image {
    opacity: 1;
    transition: opacity .3s ease;
}

.image_loading {
    opacity: 0;
}

.hidden {
    visibility: hidden;
    height: 0;
}

.disabled {
    cursor: default;
    pointer-events: none;
    opacity: vLoadingOpacity;
}

.updating {
    cursor: default;
    pointer-events: none;
    user-select: none;
    opacity: vLoadingOpacity;
    transition: opacity .3s ease;
}

/* Customized scrollbar */
.scrollbar::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: vGray25;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: vGray4;
    border-radius: 2px;
}

/* FIX for object icon */
.clickable:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    cursor: pointer;
    z-index: 1;
}

.textColorInvertByBackground {
    background: inherit;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    filter: invert(1);
}
