.form {
    position: relative;
}

.input {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: 90px;
    height: auto;
}

.actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 4px;
    right: 15px;
}

.action {

}

.action + .action {
    margin-left: 15px;
}

.iconAttach {
    cursor: pointer;
}

.iconAttach svg path {
    fill: #000;
}
