@value phone-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";
@value borderRadius: 12px;

.item {
    position: relative;
    padding: 20px 16px;
    width: 255px;
    height: 167px;
    border-radius: borderRadius;
    background-size: cover;
    background-repeat: no-repeat;
}

.name {
    position: relative;
    font-size: 1.25rem;
    line-height: 1;
}

.card {
    position: relative;
    margin-top: 40px;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
}

.card_mt {
    margin-top: 60px;
}

.lock {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.lock:before {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 58px;
    height: 58px;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 50%;
    content: '';
}

@media phone-up {
    .item {
        width: 100%;
    }
}
