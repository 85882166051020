@value vPrimary1, vRed1, vGray6, vGray14, vHoverTransition from "../../../../styles/variables.css";

.item {
    display: flex;
}

.item + .item {
    margin-top: 40px;
}

.image {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 180px;
    height: 134px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    opacity: 1;
    outline: none;
    text-decoration: none;
    transition: opacity .3s ease;
}

.image_loading {
    opacity: 0;
}

.body {
    padding: 0 0 0 20px;
    width: 100%;
}

.title {
    display: flex;
    margin-top: 4px;
    font-size: 1.25rem;
    line-height: 1;
    color: #000;
    outline: none;
    text-decoration: none;
    transition: color vHoverTransition;
}

.title:hover {
    color: vPrimary1;
}

.subtitle {
    margin-top: 4px;
    font-size: .875rem;
    color: vGray14;
}

.prices {
    margin-top: 10px;
}

.price {
    margin-right: 30px;
    font-size: 1.125rem;
    line-height: 1;
}

.price_kicks {
    color: vPrimary1;
}

.controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.control {
    position: relative;
    composes: clickable from "../../../../styles/modules/common.module.css";
}

.iconTrash {
    width: 26px;
    height: 30px;
}

.iconTrash svg path {
    fill: #000;
}

.iconPresent {
    margin: -1px 6px 0 0;
    width: 20px;
    height: 20px;
}

.iconPresent svg line,
.iconPresent svg path {
    stroke: #000;
}
