@value vPrimary1, vGray14 from "../../styles/variables.css";

.label {
    display: flex;
    align-items: center;
    width: fit-content;
}

.radio {
    position: absolute;
    margin: 0;
    z-index: -1;
    opacity: 0;
}

.radio:checked + .circle:before {
    border-color: vPrimary1;
}

.radio:not(:checked) + .circle:after {
    display: none;
}

.radio:disabled + .text {
    color: vGray14;
}

.radio:disabled + .text:after {
    background-color: vGray14;
}

.circle {
    position: relative;
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-size: 1.125rem;
    line-height: 30px;
    cursor: pointer;
}

.circle:before {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #fff;
    border: 1px solid vGray14;
    border-radius: 50%;
    content: '';
}

.circle:after {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: vPrimary1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    content: '';
}

.circle_small:before {
    width: 20px;
    height: 20px;
}

.circle_small:after {
    width: 10px;
    height: 10px;
}

.circle_medium:before {
    width: 30px;
    height: 30px;
}

.circle_medium:after {
    width: 12px;
    height: 12px;
}

.text {
    padding-left: 12px;
}
