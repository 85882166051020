@value vHoverTransition, vGray1 from "../../../styles/variables.css";


.body {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
}

.body_fullHeight {
    flex-grow: 1;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.125;
    color: #000;
    text-align: center;
    text-decoration: none;
    transition: color vHoverTransition;
    outline: none;
}

.title_link {
    cursor: pointer;
}

.desc {
    padding: 6px 0 0;
    font-weight: 500;
    line-height: 1.125;
    color: vGray1;
    text-align: center;
}
