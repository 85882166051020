@value vRed5 from "../../../../styles/variables.css";

.user {
    display: block;
}

.highlight {
    display: inline-block;
    background-color: vRed5; /* vRed5 CC only */
}
