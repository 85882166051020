@value vGray16 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.date {
    font-size: .875rem;
    font-weight: 500;
    text-align: center;
}

.date_capitalize {
    text-transform: capitalize;
}

.action {
    padding: 5px;
}

.arrow {
    border-color: vGray16;
}

@media tablet-landscape-up {
    .date {
        font-size: 1rem;
        font-weight: 700;
    }

    .action {
        padding: 10px;
    }
}
