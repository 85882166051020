@value vGutterX, vPageVerticalGapTop from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.page {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: calc(vGutterX / 2) 0 0;
}

@media tablet-landscape-up {
    .page {
        padding-top: vPageVerticalGapTop;
    }
}
