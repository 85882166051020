@value vPrimary1, vGray12, vGray1 from "../../../../styles/variables.css";

.block {
    display: flex;
    flex-direction: column;
    padding: 24px 12px 20px;
    border: 2px solid vGray12;
}

.block_active {
    border-color: vPrimary1;
}

.text {
    line-height: 1.125;
    word-break: break-word;
    hyphens: auto;
}

.image {
    position: relative;
    flex-grow: 0;
    margin-top: 12px;
    padding-bottom: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    opacity: 1;
    transition: opacity .3s ease;
}

.image_loading {
    opacity: 0;
}

.footer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
}

.info {
    margin-top: 20px;
}

.cost {
    color: vPrimary1;
    font-weight: 500;
}

.delivery {
    font-size: .875rem;
    line-height: 1;
    color: vGray1;
}

.controls {
    margin-top: 20px;
}
