@value vPrimary1, vWhite1, vRed5, vGray14, vHoverTransition from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.weekdays {
    display: flex;
    padding: 0 0 12px;
}

.weekday {
    font-size: .75rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
}

.days {
    display: flex;
    flex-wrap: wrap;
}

.item {
    max-width: calc(100% / 7);
    flex: 1 0 calc(100% / 7);
    display: flex;
    justify-content: center;
}

.item_hidden {
    visibility: hidden;
}

.date {
    position: relative;
    padding: 4px 0 10px;
    min-width: 22px;
    border-radius: 6px;
    font-size: .75rem;
    justify-content: center;
    transition: background-color vHoverTransition;
}

.date:hover {
    background-color: vWhite1;
}

.date_active {
    /*color: #000;*/
}

.date_current {
    color: vPrimary1;
    background-color: vRed5; /* vRed5 CC only */
}

.date_selected {
    border: 1px solid vPrimary1;
}

.date_marked:after {
    position: absolute;
    left: 50%;
    bottom: 5px;
    width: 4px;
    height: 4px;
    content: '';
    border-radius: 3px;
    background-color: vPrimary1;
    transform: translateX(-50%);
}

@media tablet-landscape-up {
    .weekday {
        font-size: 1rem;
    }

    .date {
        min-width: 26px;
        font-size: 1rem;
    }
}
