@value phone-only, phone-up, tablet-up, tablet-landscape-up, desktop-up, big-desktop-up, phone, tablet, tablet-landscape, desktop, big-desktop from "../../styles/breakpoints.css";

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

@media phone-only {
    .container {
        max-width: 100%;
    }
}

@media phone-up {
    .container {

    }
}

@media tablet-up {
    .container {
        max-width: tablet;
        margin-right: auto;
        margin-left: auto;
    }
}

@media tablet-landscape-up {
    .container {
        max-width: tablet-landscape;
    }
}

@media desktop-up {
    .container {
        max-width: desktop;
    }
}

@media big-desktop-up {
    .container {
        max-width: big-desktop;
    }
}
