@value vHoverTransition, vPrimary1, vGray13, vGray14, vGray1 from "../../styles/variables.css";

.wrap {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.linkEdit {
    position: absolute;
    top: 20px;
    right: 20px;
    outline: none;
}

.linkEdit_active {
    visibility: hidden;
}

.user {
    padding: 8px 0 0;
}

.userName {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    line-height: 1;
}

.accountStatus {
    color: vGray14;
    padding-top: 21px;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.data {
    display: flex;
    justify-content: center;
    margin-top: 26px;
}

.dataItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 50%;
    padding: 0 14px 0 0;
}

.dataItem + .dataItem {
    justify-content: flex-start;
    border-left: 2px solid vGray13;
    padding: 0 0 0 35px;
}

.dataText {
    font-size: 1rem;
    line-height: 1.45;
    color: vGray1;
    white-space: nowrap;
    transition: color vHoverTransition;
}

.dataText:hover {
    color: #000;
}

.text {
    margin-top: 14px;
    text-align: center;
    color: #000;
}

.online {
    margin-top: 16px;
    text-align: center;
}

.onlineText,
.onlineTime {
    font-size: 1rem;
    line-height: 1;
    color: vGray1;
}

.onlineText + .onlineTime {
    padding-left: 5px;
}

.onlineTime {
    font-weight: 700;
}

.qr {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.qrCodsSVG {
    cursor: pointer;
}

.iconQR svg * {
    fill: #000 !important;
}
