@value vGray4 from "../../styles/variables.css";

.content {
    unicode-bidi: plaintext;
    word-break: break-word;
    white-space: pre-wrap;
    outline: none;
}

.content:empty::before {
    content: attr(placeholder);
    color: vGray4;
    cursor: text;
}

.content:empty:focus::before {
    /*content: "";*/
}

.sticker {
    display: inline-block;
    vertical-align: middle;
    margin-top: -8px;
    height: 24px;
}
