@value vGutterX from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.pageContainer {
    display: flex;
    flex-direction: column;
    padding-left: vGutterX;
    padding-right: vGutterX;
    width: 100%;
}

@media tablet-up {
    .pageContainer {
        align-items: center;
        justify-content: center;
    }
}
