@value vRadius, vWhite1, vWhite2, vGray0, vGray2, vGray3, vGray4, vGray11 from "../../styles/variables.css";
@value vDefaultHeight: 40px;
@value vLargeHeight: 52px;

/* fix open native calendar on click by field */
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    z-index: 1;
}

input[type="date"]::-webkit-clear-button {
    display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.reset {
    width: 100%;
    max-width: 100%;
    min-width: vDefaultHeight;
    border: none;
    outline: none;
}

.clear {
    composes: reset;
    background-color: #fff;
    font-size: 1rem;
    font-weight: normal;
    color: vGray11;
}

.default {
    composes: reset;
    height: vDefaultHeight;
    padding: 6px 15px;
    border: 1px solid vGray3;
    border-radius: 8px;
    background-color: vWhite2;
    font-size: .875rem;
}

.dynamic {
    composes: reset;
    height: vLargeHeight;
    padding: 20px 14px 0;
    border: 1px solid vWhite1;
    border-radius: 8px;
    background-color: vWhite1;
    font-size: 1.25rem;
    line-height: 1;
    transition: background-color .3s ease;
}

.placeholder {
    position: absolute;
    top: 50%;
    left: 14px;
    right: 14px;
    padding: 0 0 2px;
    background-color: vWhite1;
    font-size: 1.25rem;
    line-height: 1;
    color: vGray4;
    pointer-events: none;
    transform: translateY(-50%);
    transition: font-size .3s ease, top .3s ease, transform .3s ease, padding .3s ease;
}

.dynamic:hover {

}

.dynamic:disabled {
    color: vGray4;
}

.dynamic:focus + .placeholder,
.dynamic:not(:placeholder-shown) + .placeholder,
.placeholder_active {
    top: 1px;
    padding-top: 6px;
    font-size: 1rem;
    transform: translateY(0);
}

.transparent {
    composes: reset;
    height: vDefaultHeight;
    padding: 12px 36px 12px 22px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: vRadius;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 300;
}

.transparent::-webkit-input-placeholder {
    color: vGray0;
}
.transparent::-moz-placeholder {
    color: vGray0;
}
.transparent:-ms-input-placeholder {
    color: vGray0;
}

.gray {
    composes: reset;
    height: vDefaultHeight;
    padding: 0 14px;
    background-color: vGray2;
    border: 1px solid transparent;
    border-radius: vRadius;
    font-size: 1rem;
    line-height: 1;
    color: #000;
}

.gray:disabled {
    color: vGray4;
}

.grouped-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}
