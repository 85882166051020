@value vGutterX, vGray11 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.auth {
    max-width: 100%;
}

.info {
    text-align: center;
}

.form {
    margin-top: 60px;
}

.timer,
.confirm {
    padding: 20px 0 0;
}

.error {
    padding-top: 20px;
    padding-bottom: 20px;
}

.apps {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
}

.appLink {
    min-height: 40px;
}

.modalAppsText {
    padding: 0 30px;
    font-size: 1.25rem;
    text-align: center;
}

.modalAppsAction {
    padding: 40px 0 30px;
    text-align: center;
}

.modalAppsLink {
    display: inline-block;
}

@media tablet-up {
    .auth {
        width: 340px;
    }
}
