@value vGutter, vGray14 from "../../../../styles/variables.css";
@value phone-up, tablet-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.block {
    margin-left: vGutter;
    margin-right: vGutter;
    padding: 30px 20px;
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
    width: 28%;
}

.column_chart {
    width: 46%;
}

.columnTitle {
    padding-top: 5px;
    font-size: 1.75rem;
    font-weight: 500;
    white-space: nowrap;
}

.columnTitle_next {
    text-align: right;
}

.columnText {
    font-size: 1.125rem;
    line-height: 1.2;
}

.columnText_next {
    text-align: right;
}

.columnTitle_gray,
.columnText_gray {
    color: vGray14;
}

.chart {
    margin-left: auto;
    margin-right: auto;
}

@media phone-up {
    .block {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media tablet-up {
    .block {
        margin-left: 0;
        margin-right: 0;
        justify-content: space-evenly;
    }

    .column {
        width: 25%;
    }

    .column_chart {
        width: 30%;
    }

    .columnTitle {
        padding-top: 30px;
        font-size: 1.5rem;
        text-align: center;
    }

    .columnText {
        font-size: 1.25rem;
        line-height: 1.2;
        text-align: center;
    }

    .columnTitle_next,
    .columnText_next {
        text-align: center;
    }
}

@media desktop-up {
    .block {
        justify-content: center;
    }

    .column {
        width: 20%;
    }

    .column_chart {
        width: 36%;
    }
}

@media big-desktop-up {
    .column {
        width: 16%;
    }

    .column_chart {
        width: 35%;
    }
}
