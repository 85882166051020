@value vGray0, vBlue6 from "../../../../styles/variables.css";
@value desktop-up from "../../../../styles/breakpoints.css";

.item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    overflow: hidden;
}

.item_clickable {
    cursor: pointer;
}

.item_active {
    background-color: vGray0;
}

.imgWrap {
    width: 24px;
    height: 24px;
}

.img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.count {
    position: absolute;
    top: 12px;
    right: 0;
    font-size: .75rem;
    line-height: 1;
    color: #fff;
}

@media desktop-up {
    .item {
        width: 58px;
        height: 58px;
    }

    .imgWrap {
        width: 32px;
        height: 32px;
    }
}
