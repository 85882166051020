@value vPrimary1, vGray12, vRadius from "../../styles/variables.css";

.block {
    background-color: #fff;
    border-radius: vRadius;
}

.block_bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.block_bordered {
    border: 2px solid vGray12;
}

.block_borderedActive {
    border-color: vPrimary1;
}

.block_borderedGray {
    background-color: vGray12;
}
