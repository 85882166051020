@value vGutter from "../../../../styles/variables.css";

.title {
    margin: 20px 0 16px 24px;
    font-size: 1.25rem;
    font-weight: 400;
}

.item {

}
