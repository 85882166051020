@value vPrimary1 from "../../styles/variables.css";

.periodsActions {
    margin-top: 20px;
}

.periodsAction + .periodsAction {
    margin-left: 5px;
}

.periodsAction_active {
    background-color: vPrimary1;
    border-color: vPrimary1;
    color: #fff;
    cursor: default;
}
