.body {
    padding: 24px 6px;
}

.title {
    height: 40px;
    font-size: 1.125rem;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
