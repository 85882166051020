@value vGutter, vHoverTransition, vGutterX, vGray1, vPrimary1 from "../../../../styles/variables.css";
@value tablet-up from "../../../../styles/breakpoints.css";

.item {
    display: flex;
    padding: 20px vGutter;
}

.item + .item {
    margin-top: calc(vGutterX / 2);
}

.body {
    padding-right: calc(vGutterX / 2);
    flex-grow: 1;
}

.title {

}

.textId {
    font-size: 1rem;
}

.textTitle {
    font-size: 1.125rem;
}

.content {
    padding: 10px 0 0;
}

.titleLink,
.contentLink {
    color: #000;
    text-decoration: none;
    transition: color vHoverTransition;
}

.contentLink {
    display: inline-block;
    vertical-align: top;
}

.titleLink:hover {
    color: vPrimary1;
}

.contentImage > img,
.contentVideo {
    width: auto;
    max-height: 80px;
}

.info {

}

.date {
    text-align: right;
    color: vGray1;
}

.status {
    padding: 10px 0 0;
    text-align: right;
}

@media tablet-up {
    .item {
        padding-left: 20px;
        padding-right: 20px;
    }
}
