@value vPrimary1 from "../../styles/variables.css";

.footer {

}

.copyright {
    text-align: center;
}

.copyrightIcon {
    margin: 10px auto 0;
}

.copyrightIcon svg path {
    fill: vPrimary1;
}
