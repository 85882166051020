@value vGutterX, vRed1, vYellow3 from "../../../../styles/variables.css";
@value tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.content {
    margin-top: calc(vGutterX / 2);
}

.blockText {
    margin-top: 20px;
    text-align: center;
}

.desc {
    margin-top: 10px;
    text-align: center;
}

.controls {
    margin-top: 20px;
}

.text {
    font-size: 1rem;
    line-height: 1;
}

.text_highlight {
    color: vRed1;
}

@media tablet-landscape-up {
    .blockText,
    .desc {
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
    }
}

@media desktop-up {

}

@media big-desktop-up {
    .blockText,
    .desc {
        max-width: 50%;
    }
}
