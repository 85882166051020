@value vPrimary1, vWhite2 from "../../styles/variables.css";

.item {
    width: 100%;
}

.item + .item {
    margin-top: 1px;
}

.item_highlight:hover .body {
    background-color: vWhite2;
}

.item .body {
    border-radius: 8px;
}

.body {
    flex-direction: row;
}

.avatar {
    display: flex;
}

.avatar_top {
    align-items: flex-start;
}

.avatar_middle {
    align-items: center;
}

.avatar_bottom {
    align-items: flex-end;
}

.avatarImageWrap {
    position: relative;
}

.content {
    flex-grow: 1;
    padding-left: 20px;
    overflow: hidden;
}

.online {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: vPrimary1;
    border-radius: 4px;
    content: '';
}

.name {
    font-size: 1.25rem;
    color: #000;
}
