@value vGutter, vRadius, vPrimary1, vGray15 from "../../../../styles/variables.css";

.slideDown {

}

.header {
    display: flex;
    justify-content: space-between;
    padding: 20px 36px 20px 24px;
    cursor: pointer;
}

.body {
    padding: 0 24px 32px;
}

.title {
    display: flex;
    align-items: center;
    padding-right: 8px;
    min-height: 48px;
    font-size: 1.25rem;
    line-height: 1.2;
}

.chevronWrap {
    display: flex;
    align-items: center;
}

.chevron {
    border-color: vPrimary1;
}
