@value vGutter, vHoverTransition, vLinkColor, vPrimary1, vGray1, vGray14 from "../../styles/variables.css";

.footer {
    flex: 0 0 auto;
    padding: 10px vGutter;
}

.copyright {
    margin-top: 30px;
}

.copyrightText {
    font-size: .875rem;
    line-height: 1;
    text-align: center;
}

.copyrightIcon {
    margin: 15px auto 0;
}

.copyrightIcon svg path {
    fill: vPrimary1;
}
