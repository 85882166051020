@value vPrimary1, vGray1 from "../variables.css";

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 38px;
    height: 38px;
    padding-left: 1px;
    padding-right: 1px;
    border: 2px solid transparent;
    border-radius: 38px;
    font-size: .875rem;
    overflow: hidden;
    white-space: nowrap;
}

.label + .label {
    margin-left: 4px;
}

.labelWhite {
    background-color: #fff;
    border-color: vPrimary1;
    color: vPrimary1;
}

.labelBlue {
    background-color: vPrimary1;
    border-color: #fff;
    color: #fff;
}
