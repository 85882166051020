@value vLinkColor, vGray10, vGray14 from "../../styles/variables.css";

.field {
    border-bottom: 2px solid vGray10;
}

.fieldError_center {
    right: 0;
    text-align: center;
}

.label {
    font-size: .875rem;
    line-height: 1;
    color: vGray14;
}

.input {
    max-width: 130px;
    padding-left: 36px;
    padding-right: 10px;
    font-size: 1rem;
    letter-spacing: 10px;
}

.inputField {
    height: 40px;
    background-color: inherit;
    color: vGray14;
    caret-color: vGray14;
}

.inputField_filled {
    color: #000;
}

.selectField {
    padding-left: 0;
    padding-right: 0;
    font-size: 1rem;
}

.selectText {
    padding-left: 10px;
}

.confirm {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.privacy {
    margin-top: 30px;
    font-size: .875rem;
}

.privacyRow {
    text-align: center;
}

.privacyRow + .privacyRow {
    margin-top: 4px;
}

.privacyText {
    color: vGray14;
}

.privacyLink {
    color: vLinkColor;
}

.controls {
    margin-top: 28px;
}
