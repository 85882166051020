@value vGutterX, vPrimary1, vWhite1, vRed1, vRed2, vRed3 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {
    position: relative;
}

.basket {
    margin-top: calc(vGutterX / 2);
    padding: 12px;
    transition: opacity .3s ease;
}

.updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.body {
    padding: 0 0 36px;
}

.subtitle {
    margin: 30px 0 0 8px;
    font-size: 1.25rem;
    font-weight: 500;
}

.footer {
    padding: 20px 0 0;
    border-top: 2px solid vWhite1;
}

.footerPrices {
    display: flex;
    justify-content: space-between;
}

.footerPrice {
    text-align: right;
}

.footerPriceText {
    font-size: 1.5rem;
    text-transform: uppercase;
}

.footerPriceCurrent {
    font-size: 1.5rem;
}

.footerPriceOld {
    font-size: 1.125rem;
    color: vRed1;
    text-decoration: line-through;
}

.footerPriceCv {
    font-size: 1.125rem;
    color: vPrimary1;
}

.footerControls {
    padding: 44px 0 0;
}

.footerControlSubmit {
    font-size: 1.125rem;
}

.footerControlSubmit_fontLarge {
    font-size: 1.25rem;
}

.blockMessage {
    margin-top: calc(vGutterX / 2);
    padding: 10px 24px;
    line-height: 1.2;
}

.blockMessage_notice {

}

.blockMessage_warning {
    color: vRed1;
}

.blockMessage_error {
    background-color: vRed2;
    color: vRed3;
}

@media tablet-landscape-up {
    .basket {
        margin-top: calc(vGutterX / 2);
        padding: 24px 24px 40px;
    }

    .subtitle {
        margin-left: 0;
    }
}
