@value vRadius, vPrimary1, vGray12 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.addressNew {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 160px;
    padding: 12px;
    cursor: pointer;
    background-color: vGray12;
    border-radius: vRadius;
}

.addressNew:hover .addressNewText {
    color: #000;
}

.addressNew:hover .iconAdd:before,
.addressNew:hover .iconAdd:after {
    background-color: #000;
}

.addressNewText {
    margin-left: 16px;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
    color: vPrimary1;
    transition: color .3s ease;
}

.iconAdd {
    position: relative;
    width: 24px;
    height: 24px;
}

.iconAdd:before,
.iconAdd:after {
    position: absolute;
    background-color: vPrimary1;
    content: '';
    transition: background-color .3s ease;
}

.iconAdd:before {
    width: 25px;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.iconAdd:after {
    width: 2px;
    height: 25px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

@media tablet-landscape-up {
    .addressNew {
        padding: 30px 16px;
    }
}
