@value vGutter, vGray2 from "../../styles/variables.css";

.container {

}

.container + .block {
    margin-top: vGutter;
}

.block {
    padding-left: 12px;
    padding-right: 12px;
}

.blockBody {
    padding-bottom: 0;
}

.list {

}

.list:nth-child(even) {
    background-color: vGray2;
}
