@value vGutter, vPrimary1, vGray14 from "../../../../styles/variables.css";

.title {

}

.titleValue {
    font-size: 1.25rem;
}

.titleUnit {
    margin-left: 10px;
    font-size: .875rem
}

.log {
    margin-top: 24px;
}

.desc {
    margin-top: calc(3 * vGutter);
    font-size: .875rem;
    color: vGray14;
}

.actions {
    margin-top: 20px;
}

.action {
    padding-left: 14px;
    padding-right: 14px;
}

.action + .action {
    margin-left: 5px;
}

.action_active {
    background-color: vPrimary1;
    border-color: vPrimary1;
    color: #fff;
}
