@value vRadius, vPrimary1, vGreen1, vGray10, vGray12, vRed2 from "../../../../styles/variables.css";
@value phone-up, tablet-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.block {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 12px;
    border: 2px solid vGray12;
    border-radius: vRadius;
    cursor: pointer;
    transition: border-color .3s ease;
}

.block:not(.block_active):hover {
    border-color: vGray10;
}

.block_active {
    border-color: vPrimary1;
    cursor: default;
}

.label {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: .625rem;
    font-weight: 600;
    color: vGreen1;
    text-transform: uppercase;
}

.name {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.2;
}

.address {
    flex-grow: 1;
    font-size: 1.125rem;
    line-height: 1.2;
}

.controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.control {
    color: vPrimary1;
    transition: color .3s ease;
}

.control:hover {
    color: #000;
}

.control_danger {
    color: vRed2;
}

@media tablet-landscape-up {
    .block {
        padding: 30px 16px 16px;
    }
}
