@value vPrimary1, vGray1, vGreen1 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.link {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
}

.link_active {
    cursor: default;
}

.link:hover .text,
.link_active .text {
    color: vPrimary1;
}

.linkChild {
    padding-top: 15px;
}
