@value vGutter, vPrimary1, vGray1, vGray4, vGray7, vColorPaymentStatusDefault, vColorPaymentStatus0, vColorPaymentStatus1, vColorPaymentStatus2, vColorPaymentStatus3, vColorPaymentStatus4 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.post {
    background-color: #fff;
}

.header {
    display: flex;
    padding: 16px 6px;
}

.headerImage {
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.headerContent {
    padding: 0 15px;
}

.headerAuthor {
    padding-top: 5px;
    font-size: 1.25rem;
    line-height: 1;
}

.headerInfo {
    padding-top: 6px;
}

.headerRank {
    font-size: 1.25rem;
    line-height: 1;
    color: vPrimary1;
}

.headerDate {
    font-size: 1.25rem;
    line-height: 1;
    color: vGray1;
}

.headerDot {
    padding: 0 5px;
    font-size: 1.25rem;
    line-height: 1;
    color: vGray1;
}

.headerMenu {
    margin-left: auto;
}

.mainImage {
    position: relative;
}

.attaches {
    padding: 4px 24px 0;
}

.body {
    padding: 20px 0 0;
}

.title {
    display: block;
    padding: 0 vGutter;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #000;
    word-break: break-word;
    hyphens: auto;
    text-decoration: none;
    transition: color .3s ease;
}

a.title:hover {
    color: vGray7;
}

.status {
    padding: 16px vGutter 0;
}

.statusBadge {
    display: inline-block;
    padding: 9px 13px;
    border-radius: 6px;
    background-color: vColorPaymentStatusDefault;
    color: #fff;
    font-size: .8125rem;
    line-height: 1;
    text-transform: uppercase;
}

.checkStatusColor0,
.checkStatusColor2,
.checkStatusColor4 {
    color: #000;
}

.checkStatusColor0 {
    background-color: vColorPaymentStatus0;
}

.checkStatusColor1 {
    background-color: vColorPaymentStatus1;
}

.checkStatusColor2 {
    background-color: vColorPaymentStatus2;
}

.checkStatusColor3 {
    background-color: vColorPaymentStatus3;
}

.checkStatusColor4 {
    background-color: vColorPaymentStatus4;
}

.content {
    padding: 16px 0 0;
}

.contentDescription {
    padding: 0 6px;
}

.contentText {
    display: inline;
    margin-right: 6px;
    font-size: 1.25rem;
    color: #000;
    text-decoration: none;
    transition: color .3s ease;
}

a.contentText:hover {
    color: vGray7;
}

.readMore {
    font-size: 1.25rem;
    color: vGray4;
    cursor: pointer;
    text-decoration: none;
    transition: color .3s ease;
}

.readMore:hover {
    color: vGray1;
}

.readMore_mt {
    margin-top: 10px;
}

.customers {
    padding: 16px vGutter 0;
    min-height: 64px;
}

.customersDesc {
    font-weight: 500;
}

.footer {
    display: flex;
    padding: 20px vGutter;
}

.footerLeft,
.footerRight {
    display: flex;
    align-items: center;
}

.footerRight {
    margin-left: auto;
}

.footerItem {
    display: flex;
    align-items: center;
    position: relative;
}

.footerItem + .footerItem {
    margin-left: 20px;
}

.footerItem_clickable {
    composes: clickable from "../../styles/modules/common.module.css";
}

.footerItemIcon {
    width: 32px;
    height: 32px;
}

.footerAction {
    position: relative;
    line-height: 0;
}

.footerCounter {
    padding: 0 10px;
    font-size: 1.125rem;
    line-height: 32px;
    color: vGray4;
    text-decoration: none;
}

.footerCounter_disabled {
    cursor: default;
}

.textHighlight {
    color: vPrimary1;
    white-space: nowrap;
}

.iconLikeOff svg path {
    stroke: #000;
}

.iconComment svg path {
    stroke: #000;
}

.iconCart svg path {
    stroke: #000
}

.iconShop svg path:not(:nth-child(3)) {
    stroke: #000;
}

.iconShop svg path:nth-child(3) {
    fill: #000;
}

.popoverShare {
    min-width: initial;
}

@media tablet-landscape-up {
    .header {
        padding: 45px 24px 24px;
    }

    .title {
        padding: 0 24px;
    }

    .status {
        padding: 16px 24px 0;
    }

    .contentDescription {
        padding: 0 24px;
    }

    .customers {
        padding: 16px 24px 0;
    }

    .customersDesc {
        font-size: 1.125rem;
    }

    .footer {
        padding: 40px 24px 20px;
    }
}
