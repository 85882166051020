@value vGutter, vGutterX, vGray4, vGray14 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.pageTitle {
    display: flex;
    justify-content: space-between;
}

.block {
    position: relative;
    padding: 20px 10px;
}

.block_margin {
    margin-top: calc(vGutterX / 2);
    margin-bottom: calc(vGutterX / 2);
}

.blockTitle {
    font-size: 1.25rem;
    line-height: 1;
    color: #000;
    text-align: center;
}

.blockTextInfo {
    max-width: 80%;
    margin: 0 auto;
    padding: 30px 0;
    font-size: 1.25rem;
    color: vGray14;
    text-align: center;
}

.blockCard {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card {
    max-width: calc(50% - vGutter / 2);
    flex-basis: calc(50% - vGutter / 2);
    margin-bottom: vGutter;
}

.card_link {
    cursor: pointer;
}

.charts {

}

.stats {
    margin-top: calc(vGutterX / 2);
}

.slider {
    margin-top: calc(vGutterX / 2);
    margin-left: -6px;
    margin-right: -6px;
}

.slider_gapBottom {
    margin-bottom: calc(vGutterX / 2);
}

.sliderItem {
    width: 100%;
    padding-left: 6px;
    padding-right: 6px;
}

.sliderItem_link {
    text-decoration: none;
}

.selectOptionActive {
    pointer-events: none;
    color: vGray4;
}

.restValue {
    white-space: nowrap;
}

@media tablet-up {

}

@media tablet-landscape-up {
    .block {
        padding-left: 20px;
        padding-right: 20px;
    }

    .blockTextInfo {
        max-width: 60%;
        padding: 40px 0;
    }

    .stats {
        padding-left: 20px;
        padding-right: 20px ;
    }
}

@media desktop-up {
    .stats {
        margin-top: 0;
    }
}

@media big-desktop-up {

}
