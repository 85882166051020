@value vGutter, vGray14, vGray15 from "../../../../styles/variables.css";

.item {
    width: 100%;
    border-bottom: 1px solid vGray15;
}

.body {
    padding: 16px 4px;
    flex-direction: row;
    justify-content: space-between;
}

.name {
    font-size: .875rem;
    color: #000;
}

.date {
    font-size: .875rem;
    color: vGray14;
}

.value {
    font-weight: 500;
    color: #000;
}
