@value vPrimary1, vWhite1, vGray10, vGray14, vBlue10 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.wrap {
    position: relative;
}

.cell {
    max-width: calc(100% / 7);
    flex: 1 0 calc(100% / 7);
}

.days {
    display: flex;
    margin-bottom: 10px;
    padding: 0 0 10px;
    border-bottom: 1px solid vGray10;
}

.daysItem {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.list_loading {
    composes: updating from "../../../../styles/modules/common.module.css";
}

.events {
    padding: 4px 3px;
}

.posts {
    padding-top: 10px;
    max-height: 400px;
    background-color: vWhite1;
    overflow: visible auto;
}

.greedMonth {
    padding: 6px 0 0;
}

.greedHeader {
    padding-bottom: 10px;
    border-bottom: 1px solid vGray10;
}

.greedBody {
    padding: 24px 0 16px;
}

.greedDate {
    margin: 0 0 18px;
    padding: 5px 8px 15px;
}

@media tablet-landscape-up {
    .wrap {
        padding: 14px 0 0;
    }

    .days {
        padding-bottom: 0;
        border-bottom: none;
    }
    .list {
        border-top: 1px solid vGray10;
    }
}
