@value vGutter, vHoverTransition, vGray3, vGray7, vGray14 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.wrap {
    position: relative;
    padding: calc(2 * vGutter) vGutter;
    display: flex;
}

.menu {
    position: absolute;
    top: 16px;
    right: 16px;
}

.menuBody {
    padding: 6px 0;
}

.menuList {
    padding: 0 40px;
}

.menuListItem {
    padding: 20px 0;
    justify-content: center;
    font-size: 1.25rem;
    line-height: 1;
    white-space: nowrap;
    transition: color vHoverTransition;
}

.menuListItem + .menuListItem {
    border-top: 1px solid vGray3;
}

.menuListItem:hover {
    background-color: inherit;
    color: vGray7;
}

.content {
    padding-left: 24px;
    flex-basis: 100%;
}

.date {
    color: vGray14;
}

.name {
    margin-top: 8px;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
}

.status {
    margin-top: 10px;
    color: vGray14;
    word-break: break-word;
    hyphens: auto;
}

.text {
    margin-left: 8px;
    color: vGray14;
}

.actions {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.action {

}

.action_button {
    margin-right: 16px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: .875rem;
}

.friends {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@media tablet-landscape-up {
    .wrap {
        padding: 22px 24px;
    }

    .menuListItem {
        padding: 20px 40px 20px 0;
        justify-content: flex-start;
    }

    .actions {
        flex-wrap: initial;
    }
}
