@value vGray14 from "../../../../styles/variables.css";

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.2;
}

.subtitle {
    font-size: .8125rem;
    line-height: 1;
    color: vGray14;
}

.value {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1;
}

.chart {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
}
