@value vGutter, vGray7 from "../../../../styles/variables.css";
@value tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.body {
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
}

.item {
    max-width: calc(50% - vGutter / 2);
    flex-basis: calc(50% - vGutter / 2);
}

@media tablet-landscape-up {
    .item {
        max-width: calc(33.33% - vGutter / 2);
        flex-basis: calc(33.33% - vGutter);
    }
}

@media desktop-up {

}

@media big-desktop-up {
    .item {
        max-width: calc(25% - vGutter / 2);
        flex-basis: calc(25% - vGutter);
    }
}
