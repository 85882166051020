@value vPrimary1, vHoverTransition from "../../../../styles/variables.css";

.showAll {
    margin-top: 12px;
    color: vPrimary1;
    transition: color vHoverTransition;
}

.showAll:hover {
    color: #000;
}

.modalBody {
    padding-left: 0;
    padding-right: 0;
}
