@value vPrimary1 from "../../../../styles/variables.css";

.more {
    composes: link from "../MenuNavLink/MenuNavLink.module.css";
}

.icon {
    composes: icon from "../MenuNavLink/MenuNavLink.module.css";
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
}

.chevron {
    border-color: vPrimary1;
}

.text {
    composes: text from "../MenuNavLink/MenuNavLink.module.css";
}
