@value vGutter, vRadius, vWhite2 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.form {

}

.formFieldWrap {
    padding: 20px vGutter;
}

.textReply {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0 vGutter;
    padding: 8px 15px;
    background-color: vWhite2;
    border-radius: vRadius;
}

.textReplyClose {
    width: auto;
}

.textReplyCloseIcon {
    composes: close from "../../styles/modules/close.module.css";
}

.textReplyBody {
    padding-left: 24px;
}

.textReplyUser {

}

.textReplyComment {

}

@media tablet-landscape-up {
    .formFieldWrap {
        padding: 20px 24px 30px;
    }

    .textReply {
        margin: 0 24px;
        padding: 8px 15px;
    }
}
