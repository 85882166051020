@value vPrimary1, vWhite1, vGray10, vGray14, vRed5, vHoverTransition from "../../../../styles/variables.css";

.cell {
    padding: 0 2px;
    min-height: 148px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent vGray10 vGray10 transparent;
}

/* right col cells */
.cell:nth-child(7n) {
    border-right-color: transparent;
}

.dateWrap {
    display: flex;
    justify-content: center;
    margin: 3px 0;
    min-height: auto;
}

.date {
    padding: 3px;
    min-width: 16px;
    flex-direction: column;
    border-radius: 6px;
    font-size: .75rem;
    line-height: 1;
    color: vGray14;
    justify-content: center;
    text-decoration: none;
    transition: background-color vHoverTransition;
}

.date:hover {
    background-color: vWhite1;
}

.date_active {
    color: #000;
}

.date_current {
    color: vPrimary1;
    background-color: vRed5; /* vRed5 CC only */
}

.content {

}
