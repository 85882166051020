@value vGray16 from "../../styles/variables.css";

.modalHeader {
    border-bottom: 1px solid vGray16;
}

.modalTitle {
    font-size: 1.5rem;
    text-transform: none;
}

.modalBody {
    padding: 20px 25px 25px;
}
