@value tablet-landscape-up from "../../styles/breakpoints.css";

.nav {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    list-style: none;
}

.navItem {
    position: relative;
    height: 32px;
}

.navItem + .navItem {
    margin-left: 12px;
}

.link {
    position: relative;
    display: block;
    font-size: 0;
}

.modalText {
    padding: 50px 40px 40px;
    text-align: center;
}

.modalActions {
    padding: 0 0 20px;
}

.modalAction {

}

.modalAction + .modalAction {
    margin-top: 10px;
}

.icon {
    width: 32px;
    height: 32px;
}

.icon svg * {
    fill: #fff;
}

.badge {
    position: absolute;
    top: -10px;
    right: -10px;
}

.iconDropDown {
    width: 20px;
}

.iconMenuItem {
    display: block;
    margin-right: 10px;
}

@media tablet-landscape-up {
    .navItem + .navItem {
        margin-left: 24px;
    }
}
