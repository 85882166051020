@value vWhite1, vRed5 from "../../../../styles/variables.css";

.message {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

/* focused background */
.message:before {
    position: absolute;
    background-color: rgba(0, 0, 0, .3);
    content: '';
    top: 0;
    left: -50vw;
    right: -50vw;
    bottom: 0;
    transition: opacity .6s;
    opacity: 0;
    z-index: -1;
}

.message + .message {
    margin-top: 15px;
}

.message_focused:before {
    opacity: 1;
}

.message_sender {
    margin-left: auto;
}

.message_receiver {
    margin-right: auto;
}

.message_sender_bg,
.message_receiver_bg {
    padding: 4px 12px;
    border-radius: 12px;
}

.message_sender_bg {
    background-color: vRed5; /* vRed5 CC only */
}

.message_receiver_bg {
    background-color: vWhite1;
}

.content {

}

.info {
    display: flex;
    justify-content: flex-end;
    padding: 2px 0;
}

.date {
    font-size: .75rem;
    line-height: 1.2;
}

.status {
    display: flex;
    justify-content: flex-end;
    padding: 0 4px 0 6px;
}

.error {
    padding-left: 4px;
}

.modalContentOverlay {
    composes: overlay from "../../../../styles/modules/overlay.module.css";
    composes: overlay_dark from "../../../../styles/modules/overlay.module.css";
}

.modalContent {
    background-color: transparent;
    box-shadow: none;
}

.modalContentBody {
    max-height: 100%;
    padding: 0;
    overflow: hidden;
}

.modalContentLoader {
    min-width: 40px;;
}

.modalContentImageWrap,
.modalContentVideoWrap {
    display: flex;
    justify-content: center;
}

.modalContentImageWrap_loading,
.modalContentVideoWrap_loading {
    composes: hidden from "../../../../styles/modules/common.module.css";
}

.modalContentImage {

}

.modalContentVideo {

}
