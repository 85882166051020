@value vGutterX from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.registration {
    max-width: 100%;
    padding: 20px calc(vGutterX / 2);
}

@media tablet-up {
    .registration {
        width: 360px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
