@value vWhite1, vGray14 from "../../../../styles/variables.css";

.event {
    display: block;
    max-width: 100%;
    max-height: 38px; /* 2 line */
    padding: 5px 6px;
    background-color: vWhite1;
    border-radius: 8px;
    text-align: left;
    text-decoration: none;
    overflow: hidden;
}

.event + .event {
    margin-top: 4px;
}

.time {
    padding-right: 4px;
    float: left;
    font-size: .75rem;
    line-height: 16px;
    color: vGray14;
    white-space: nowrap;
}

.title {
    font-size: .75rem;
    line-height: 16px;
    vertical-align: top;
}
