@value vGray14, vGray18 from "../../styles/variables.css";

.tag {
    display: inline-flex;
    align-items: center;
    padding: 3px 2px;
    background-color: vGray18;
    border-radius: 15px;
    color: #fff;
    line-height: 1;
}

.content {
    padding: 0 10px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    background-color: vGray14;
    border-radius: 50%;
}

.cross {
    width: 14px;
    height: 14px;
    composes: close from "../../styles/modules/close.module.css";
}

.cross:before {
    width: 14px;
    height: 2px;
    background-color: #fff;
}

.cross:after {
    width: 2px;
    height: 14px;
    background-color: #fff;
}
