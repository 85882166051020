@value vGutter, vGutterX, vTopNavHeight, vPrimary1 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.navBar {
    min-height: vTopNavHeight;
    background: vPrimary1;
}

.nav {
    display: flex;
    align-items: center;
    width: 100%;
    padding: vGutter;
}

@media tablet-up {
    .nav {
        padding-left: calc(vGutterX / 2);
        padding-right: calc(vGutterX / 2);
    }
}
