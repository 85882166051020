@value vRadius, vGray7, vBlue2 from "../../styles/variables.css";

.wrap {
    padding: 24px 20px 20px;
}

.body {
    padding: 0;
}

.contactsItem {
    display: flex;
}

.contactsItem + .contactsItem {
    margin-top: 12px;
}

.contactsText,
.contactsLink,
.contactsPrice {
    padding-left: 10px;
    font-size: 1.125rem;
    line-height: 1.2;
}

.contactsLink {
    color: vBlue2;
    text-decoration: none;
    outline: none;
    word-break: break-word;
}

.contactsPrice {
    color: vGray7;
}

.contactsIcon {
    margin-top: 2px;
    width: 22px;
}

.map {
    max-width: 100%;
    height: auto;
    border-radius: vRadius;
}

.iconMap {
    width: 15px;
    height: 24px;
}

.iconWeb {
    width: 20px;
    height: 20px;
}
