@value vGutter, vGutterX from "../../styles/variables.css";
@value phone-up, tablet-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.pageHeaderTitle {

}

.pageHeaderContent {
    margin-bottom: calc(vGutterX / 2);
}

.sliderStatuses {
    padding: 0 vGutter vGutterX;
}

.sliderStatusesItem {
    padding-bottom: 29px;
}

.cardContainer {
    margin-top: calc(vGutter * 2);
}

.modalIframe {
    height: 100%;
}

.modalBodyIframe {
    max-height: none;
    height: 100%;
    padding: 0 0 40px;
    overflow-y: hidden;
}

@media phone-up {
    .pageHeaderTitle {
        padding-right: 10px;
        flex-grow: 0;
    }
}

@media tablet-up {
    .sliderStatuses {
        padding: 0;
    }

    .block {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media tablet-landscape-up {
    .pageHeaderContent {
        margin-bottom: 0;
    }
}

@media desktop-up {

}

@media big-desktop-up {

}
