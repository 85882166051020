@value vPrimary1, vBlue0, vBlue6, vViolet0, vGray5, vLoadingOpacity from "../../styles/variables.css";

._reset {
    padding: 0;
    font-size: 1rem;
    line-height: 1;
    border: none;
    outline: none;
    cursor: pointer;
}

.primary:disabled,
.bordered:disabled,
.bordered2:disabled,
.transparent:disabled {
    opacity: vLoadingOpacity;
    cursor: default;
}

.primary:not(:disabled):hover {
    filter: brightness(.90);
}

.primary{
    composes: _reset;
    padding-left: 20px;
    padding-right: 20px;
    background: vPrimary1;
    border-radius: 30px;
    color: #fff;
    transition: filter .2s;
}

.bordered {
    composes: _reset;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    border: 1px solid vPrimary1;
    border-radius: 30px;
    color: vPrimary1;
    text-decoration: none;
    transition: background-color .2s, color .2s;
}

.bordered:not(:disabled):hover {
    background-color: vPrimary1;
    color: #fff;
    filter: brightness(.90);
}

.fullWidth {
    width: 100%;
}

/* deprecated */

.custom {
    composes: _reset;
}

.bordered2 {
    composes: _reset;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
    border: 1px solid vGray5;
    border-radius: 12px;
    color: #000;
    line-height: 1;
    white-space: nowrap;
}

.transparent {
    composes: _reset;
    display: flex;
    align-items: center;
    /*width: 100%;*/
    background: transparent;
    line-height: 1;
    color: #000;
    text-decoration: none;
}

.text {
    composes: _reset;
    background: none;
}

.textUpper {
    text-transform: uppercase;
}

.size32 {
    height: 32px;
}

.size36 {
    height: 36px;
}

.size40 {
    height: 40px;
}

.size44 {
    height: 44px;
}

.size46 {
    height: 46px;
}

.size48 {
    height: 48px;
}
