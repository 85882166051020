@value vGutter, vPrimary1, vRadius, vGray1, vGray2, vGray8, vBlue7, vRed4, vGray4, vGreen1 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.comment {
    display: flex;
    padding: vGutter;
}

.block {
    max-width: calc(100% - 74px); /* fix image width on loading */
}

.block_left {
    margin-left: 0;
}

.block_right {
    margin-left: auto;
    margin-right: 0;
}

.body_doubleLeft {
    margin-left: 20px;
}

.body_doubleRight {
    margin-right: 20px;
}

.content {

}

.content_colorDefault {
    background-color: vGray2;
}

.content_colorOwn {
    background-color: vPrimary1;
}

.avatar {
    position: relative;
}

.online {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: vPrimary1;
    border-radius: 4px;
    content: '';
}

.reply:before {
    padding-right: 4px;
    content: '➦';
}

.textEditable {
    padding: 4px 6px;
    background-color: vGray2;
    border-radius: calc(vRadius / 2);
    color: #000;
}

.textMore {
    padding: 0;
    font-weight: 700;
}

.textBlocked {
    color: vGray1;
}

.actions {
    display: flex;
    align-items: center;
    margin: 8px 0 0 12px;
}

.action + .action {
    margin-left: 6px;
}

.actionText {
    line-height: 1;
    color: vGray1;
    transition: color .3s ease;
}

.actionText:hover {
    color: #000;
}

.actionText_bold {
    font-weight: 700;
}

.actionText_danger {
    color: vRed4;
}

.actionText_danger:hover {

}

.actionText_success {
    color: vGreen1;
}

.menuItem {

}

.menuItem_danger {
    color: vRed4;
}

@media tablet-landscape-up {
    .comment {
        padding-left: 24px;
        padding-right: 24px;
    }

    .block_left {
        margin-left: 20px;
    }

    .block_right {
        margin-right: 20px;
    }

    .body_doubleLeft {
        margin-left: 40px;
    }

    .body_doubleRight {
        margin-right: 40px;
    }
}
